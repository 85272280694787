import React from 'react';

import { LandingCenter, LandingLayout } from '../layout';

export function FourOhFour() {
  return (
    <LandingLayout>
      <LandingCenter>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </LandingCenter>
    </LandingLayout>
  );
}

export default FourOhFour;
